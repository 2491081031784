$(document).ready(function () {
	$(document).foundation();
	$(".slider").slick({
		dots: false,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear'
	});
	$(".gallery").slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
	});

	$('.gallery').on('afterChange', function(event, slick, currentSlide, nextSlide){
		$(".gallery img").removeClass("prev-el");
		$(".gallery img").removeClass("center-ele");
		$(".gallery img").removeClass("next-ele");

		var prev = currentSlide - 1;
		var next = currentSlide + 1;

		if(currentSlide === 0) {
			prev = (slick.slideCount-1);
		}
		if(currentSlide == (slick.slideCount-1)){
			next = 0;
		}

		console.log("=====");
		console.log("prev", $(".gallery img[data-slick-index=" +prev+ "]"));
		console.log("currentSlide", $(".gallery img[data-slick-index=" +currentSlide+ "]"));
		console.log("next", $(".gallery img[data-slick-index=" +next+ "]"));
	});
	var $location = $(location).attr('href');

	if((/villas-wayak/.exec($location)) !== null) {
		$(".floating .top-bar-section ul:not(.submenu)> li:nth-child(1)").addClass('active');
		$("#mobile-menu .top-bar-section ul:not(.submenu)> li:nth-child(2)").addClass('active');
	}else if((/desarrollo/.exec($location)) !== null){
		$("header.floating").addClass('show-sub-menu');
		$(".floating .top-bar-section ul:not(.submenu)> li:nth-child(2)").addClass('active');
		$(".floating .top-bar-section ul:not(.submenu)> li:nth-child(2)").addClass('active');
		if ((/desarrollo\/ultimate/.exec($location)) !== null) {
			$(".floating .top-bar-section ul.submenu li:nth-child(1)").addClass('active');
			$("#mobile-menu .top-bar-section ul.submenu li:nth-child(1)").addClass('active');
		}else if((/desarrollo\/palm/.exec($location)) !== null){
			$(".floating .top-bar-section ul.submenu li:nth-child(2)").addClass('active');	
			$("#mobile-menu .top-bar-section ul.submenu li:nth-child(2)").addClass('active');	
		}else if((/desarrollo\/xel-ha/.exec($location)) !== null){
			$(".floating .top-bar-section ul.submenu li:nth-child(3)").addClass('active');		
			$("#mobile-menu .top-bar-section ul.submenu li:nth-child(3)").addClass('active');		
		} else if((/desarrollo\/villas/.exec($location)) !== null){
			$(".floating .top-bar-section ul.submenu li:nth-child(4)").addClass('active');		
			$("#mobile-menu .top-bar-section ul.submenu li:nth-child(4)").addClass('active');		
		}
	}else if((/galeria/.exec($location)) !== null){
		$(".floating .top-bar-section ul:not(.submenu)> li:nth-child(3)").addClass('active');	
		$("#mobile-menu .top-bar-section ul:not(.submenu)> li:nth-child(5)").addClass('active');	
	}else if((/contacto/.exec($location)) !== null){
		$(".floating .top-bar-section ul:not(.submenu)> li:nth-child(4)").addClass('active');
		$("#mobile-menu .top-bar-section ul:not(.submenu)> li:nth-child(6)").addClass('active');
	}else{
		$("#inicio").addClass('active');
		$("header.floating").addClass('transparent');
	}

	hasMap =  $('body').attr('id') == "contact";
	hasMap = hasMap || $('body').attr('id') == "home"; 
	if (hasMap) {
		var latLong = new google.maps.LatLng(21.325968,-89.421984);
		var map = new google.maps.Map(document.getElementById("mapa"), { 
			zoom: 15, 
			center: latLong, 
			mapTypeId:google.maps.MapTypeId.ROADMAP,
			disableDefaultUI:!0,
			scrollwheel:!1,
			zoomControl:!0,
			zoomControlOptions:{
				style:google.maps.ZoomControlStyle.LARGE,
				position:google.maps.ControlPosition.LEFT_CENTER
			}
		});
		var beachMarker = new google.maps.Marker({
			position: latLong,
			map: map
		});
	}

	$(function() {
	  $('#more').click(function() {
	    var link = this;


	      var target = $("#main");
	      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

	      if (target.length) {
	        $('html,body').animate({
	          scrollTop: target.offset().top
	        }, 1000);

	        return false;
	      }
	    
	  });
	});

	$('#slides').superslides();


});